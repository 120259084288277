import css from "@emotion/css";
import { Box, Flex } from "@rebass/grid/emotion";

import React, { useRef, useEffect, useState } from "react";
import { Layout, Container } from "~src/components";
import SEO from "~src/components/seo";
import { colors } from "~src/style";
import { navigateTo } from "gatsby";

export default () => {
  const video = useRef<HTMLVideoElement>(null);
  // useEffect(() => console.log("oi", video), [video]);
  const [playing, setPlaying] = useState(false);
  const playVideo = () => {
    if (video.current) {
      video.current.play();
      setPlaying(true);
    }
  };
  const pauseVideo = () => {
    if (video.current) {
      video.current.pause();
      setPlaying(false);
    }
  };
  return (
    <Layout
      cssProp={css`
        background: ${colors.blue1};
      `}
    >
      <SEO title="Had ik toen maar een huis gekocht" />
      <Container>
        <Box
          css={css`
            margin-bottom: 32px;
            text-align: center;
          `}
        >
          <img
            src={require("../assets/vers-beton-logo.png")}
            css={css`
              width: 2rem;
              display: inline-block;
              margin-top: 1rem;
            `}
          />
          <h1
            css={css`
              text-align: center;
            `}
          >
            Had ik toen maar een huis gekocht
          </h1>
          <h2
            css={css`
              text-align: center;
            `}
          >
            Tien jaar Rotterdamse woningmarkt
          </h2>
        </Box>
        <video
          css={css`
            width: 100%;
            max-width: 720px;
            display: block;
            margin: 0 auto;
          `}
          src="/intro.mp4"
          ref={video}
          onEnded={() => navigateTo("/app/")}
        />
        <Flex
          css={css`
            margin: 32px auto;
          `}
          flexDirection="column"
          alignItems="center"
        >
          <Box
            css={css`
              margin: 8px 0;
              padding: 8px 16px;
              cursor: pointer;
              font-weight: bold;
              min-width: 100px;
              text-align: center;
              color: ${colors.blue2};
              background: ${colors.white};
            `}
            onClick={() => (playing ? pauseVideo() : playVideo())}
          >
            {playing ? "Pauzeer introductie" : "Bekijk introductie"}
          </Box>
          <Box
            css={css`
              margin: 8px 0;
              padding: 8px 16px;
              cursor: pointer;
              font-weight: bold;
              min-width: 100px;
              text-align: center;
              border: 2px solid ${colors.white};
            `}
            onClick={() => navigateTo("/app/")}
          >
            Sla over
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};
